/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@-webkit-keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 54px;
}

.h2 {
  font-size: 44px;
}

.color-primary {
  color: #30a3b8;
}
.color-gray1 {
  color: #b3b3b3;
}
.color-gray2 {
  color: #cccccc;
}
.color-gray3 {
  color: #e6e6e6;
}
.color-gray4 {
  color: #f4f4f4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #30a3b8 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #30a3b8 !important;
  border-color: #30a3b8 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #4d4d4d;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #30a3b8 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #4d4d4d !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

.darkTheme {
  background: #080a08 !important;
}

div {
  font-family: "Manrope", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 54px !important;
  font-weight: 400 !important;
}

h2 {
  font-size: 44px !important;
  font-weight: 400 !important;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #5f9a9f;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #333333;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #5f9a9f;
}

.cpr {
  color: #30a3b8;
}

.cprt {
  color: #f6f9ff;
}

.bpr {
  background: #30a3b8;
}

.bprt {
  background: #f6f9ff;
}

.cse {
  color: #5f9a9f;
}

.bse {
  background: #5f9a9f;
}

.csu {
  color: #3cc13b;
}

.bsu {
  background: #3cc13b;
}

.csut {
  color: #d7f3d7;
}

.bsut {
  background-color: #d7f3d7;
}

.cwa {
  color: #f3bb1c;
}

.bwa {
  background: #f3bb1c;
}

.cwat {
  color: #fdf1d1;
}

.bwat {
  background: #fdf1d1;
}

.cda {
  color: #f03738;
}

.bda {
  background: #f03738;
}

.bdat {
  background: #fcd7d7;
}

.bcdat {
  background: #fcd7d7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  line-height: 46px;
  margin: 0;
  width: 100%;
  font-weight: 100;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  border-color: #30a3b8;
  color: black;
  background-color: #30a3b8;
}
.bton--primary:hover {
  background-color: #2fb9d1;
  border-color: #2fb9d1;
  color: white;
}
.bton--secondary {
  color: white;
  background-color: #5f9a9f;
  border-color: #5f9a9f;
}
.bton--secondary:hover {
  background-color: #57c1ca;
  border-color: #57c1ca;
  color: white;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #30a3b8;
  color: #30a3b8;
  background-color: transparent;
}
.bton--ghost:hover {
  background-color: #2fb9d1;
  border-color: #2fb9d1;
  color: white;
}
.bton--ghost--danger {
  border-color: #f03738;
  background: #fcd7d7;
  color: #f03738;
}
.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}
.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}
.bton--nacked {
  border: none;
  color: white;
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #4d4d4d;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #4d4d4d;
  display: inline-block;
}

.navWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navWrapper__navBrand {
  margin-bottom: 0;
}
.navWrapper__navBrand img {
  width: 4rem;
}
@media all and (max-width: 768px) {
  .navWrapper__navBrand img {
    width: 2.5rem;
  }
}
.navWrapper__navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.navWrapper__navItems--items {
  padding: 1rem 1.5rem;
  cursor: pointer;
  color: red;
}
.navWrapper__navItems--items a:hover {
  color: white !important;
}
@media screen and (max-width: 1040px) {
  .navWrapper__navItems {
    display: none;
  }
}
.navWrapper__mdNav {
  display: none;
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav {
    display: block;
  }
  .navWrapper__mdNav .open_mdNav {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.8rem;
    color: rgb(255, 255, 255);
  }
  .navWrapper__mdNav .mdNav_list_container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.719);
    transition: all 300ms linear;
    display: flex;
    justify-content: flex-end;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    background-color: #17202a;
    width: 25%;
    min-width: 25rem;
    height: 100%;
    padding: 1rem 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child {
    display: flex;
    justify-content: space-between;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container {
    padding-top: 0.8rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container .close_mdNav {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 2rem;
    color: rgb(255, 255, 255);
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:not(:first-child) {
    margin-left: 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li .mdnavBrand img {
    width: 5rem;
    margin-left: 1.5rem;
  }
}
@media all and (max-width: 1040px) and (max-width: 576px) {
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    width: 100%;
    min-width: 100%;
  }
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav .showmdNav {
    right: 0;
  }
}

@media all and (max-width: 1040px) {
  #dropdown-basic {
    padding: 0 !important;
  }
  .dropdown-menu {
    margin-top: 1rem !important;
  }
}
.activeSidebar {
  color: #30a3b8;
}

.notActive {
  color: white;
}

.movactiveSidebar {
  color: #30a3b8;
}

.movnotActive {
  color: white;
}

section[role=navDrop] .btn-success {
  color: #4d4d4d;
  background-color: #ffffff;
  border: none;
}
section[role=navDrop] .btn-check:active + .btn-success,
section[role=navDrop] .btn-check:checked + .btn-success,
section[role=navDrop] .btn-success.active,
section[role=navDrop] .btn-success:active,
section[role=navDrop] .show > .btn-success.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffffff;
  border: none;
}

.navbar {
  padding: 15px 0 !important;
}
.navbar .active-link.active {
  color: #24BCAA !important;
  position: relative;
}
.navbar .active-link.active::before {
  content: "";
  position: absolute;
  background-color: #24BCAA;
  width: 30px;
  height: 1.5px;
  top: 33px;
}
.navbar .navbar-nav a {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #131d24 !important;
  margin-left: 50px;
}

.navbar-nav {
  position: relative;
}

.navbarDropdown {
  background-color: #2e4859;
  padding: 28px 31px;
  position: absolute;
  z-index: 99999;
  width: 80%;
}
.navbarDropdown__heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #30a3b8;
  border-bottom: 0.5px solid #e0e0e0;
  padding-bottom: 10px;
}
.navbarDropdown ul {
  padding-left: 0;
}
.navbarDropdown ul li {
  padding-bottom: 8px;
}
.navbarDropdown ul li a {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
}
.navbarDropdown__arrowup {
  position: absolute;
  right: 35%;
  width: 20px;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 15px solid #2e4859;
  top: -6%;
}

.footer {
  background-color: #131d24;
  padding: 70px 0 20px 0;
}
.footer__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #30a3b8;
}
.footer__title--solution {
  -moz-column-count: 2;
       column-count: 2;
}
.footer ul {
  padding-left: 0;
}
.footer ul li {
  color: #ffffff;
  padding-bottom: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
}
.footer ul li a {
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  padding-bottom: 10px;
}
.footer__icon {
  color: #000;
  font-size: 30px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 5px;
  margin-right: 10px;
}
.footer__copyright {
  border-top: 0.8px solid #24BCAA;
}
.footer__copyright p {
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-align: center;
  padding-top: 25px;
}

.homeWrapper {
  margin-top: 1.5rem;
}
.homeWrapper-title {
  color: white;
  margin-top: 8rem;
}
@media all and (max-width: 1200px) {
  .homeWrapper-title {
    margin-top: 2rem;
  }
}
@media all and (max-width: 768px) {
  .homeWrapper-title {
    margin-top: 1rem;
    font-size: 35px !important;
  }
}
.homeWrapper-subtitle {
  color: white;
  font-size: 28px;
}
@media all and (max-width: 1200px) {
  .homeWrapper-subtitle {
    font-size: 24px;
  }
}
@media all and (max-width: 768px) {
  .homeWrapper-subtitle {
    font-size: 18px;
  }
}
.homeWrapper-desc {
  margin-top: 2rem;
  color: #acacac;
}
@media all and (max-width: 768px) {
  .homeWrapper-desc {
    font-size: 13px;
  }
}
.homeWrapper-buttons {
  margin-top: 3rem;
}
.homeWrapper-buttons .downloadButton {
  color: #30a3b8;
}
@media all and (max-width: 576px) {
  .homeWrapper-image {
    text-align: center;
  }
}
.homeWrapper-image img {
  width: 95%;
  border-radius: 4px;
}
@media all and (max-width: 576px) {
  .homeWrapper-image img {
    width: 50%;
  }
}
.homeWrapper-social {
  color: #acacac;
}
.homeWrapper-social ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}
@media all and (max-width: 576px) {
  .homeWrapper-social ul {
    flex-direction: row;
    justify-content: space-around;
  }
}
.homeWrapper-social ul li {
  margin-bottom: 1.3rem;
}
.homeWrapper-social ul li a {
  height: 34px;
  width: 34px;
}
.homeWrapper-social ul li a svg {
  border-radius: 34px;
  background: #343534;
  color: #ffffff;
  padding: 6px;
  font-size: 28px !important;
}
.homeWrapper-social ul .socialLine {
  height: 5rem;
  width: 0.1rem;
  background: rgb(255, 255, 255);
}
@media all and (max-width: 576px) {
  .homeWrapper-social ul .socialLine {
    display: none;
  }
}
.homeWrapper-social ul .scroll {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  letter-spacing: 8px;
}
@media all and (max-width: 768px) {
  .homeWrapper-social ul .scroll {
    display: none;
  }
}

.skillsHolder {
  margin-top: 5rem;
  padding: 1.5rem;
}
.skillsHolder h2 {
  color: white;
  margin-bottom: 3rem;
}
.skillsHolder-item {
  background: #03171b;
  color: #dddddd;
  text-align: center;
  border-radius: 4px;
  padding: 2rem;
  margin-bottom: 1rem;
}
.skillsHolder-item svg {
  font-size: 3rem;
}
.skillsHolder-item img {
  width: 100%;
  height: 7rem;
  -o-object-fit: contain;
     object-fit: contain;
  background: white;
  padding: 0.5rem;
}

.worksWrapper {
  color: white;
  margin-top: 8rem;
}
.worksWrapper-title {
  text-align: center;
  font-size: 3rem !important;
  margin-bottom: 3rem;
}
.worksWrapper .mainTitle {
  text-align: center;
  font-size: 9rem !important;
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 576px) {
  .worksWrapper .mainTitle {
    font-size: 5rem !important;
  }
}
.worksWrapper .cardsHolder {
  background: #03171b;
  margin-bottom: 5rem;
  border-radius: 4px;
  padding: 2rem;
}
.worksWrapper .cardsHolder-pills {
  display: flex;
  flex-wrap: wrap;
}
.worksWrapper .cardsHolder-pills span {
  background: #22292b;
  padding: 0.7rem;
  text-transform: uppercase;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
@media all and (max-width: 1200px) {
  .worksWrapper .cardsHolder-pills span {
    font-size: 13px;
  }
}
.worksWrapper .cardsHolder-desc {
  margin-top: 3rem;
}
.worksWrapper .cardsHolder-desc-paragraph {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #8d8d8d;
}
.worksWrapper .cardsHolder-image img {
  width: 100%;
}
@media all and (max-width: 768px) {
  .worksWrapper .cardsHolder-image img {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

footer {
  background: #151616;
  color: rgb(167, 167, 167);
}
footer .footerWrapper {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contactHolder {
  color: white;
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.contactHolder .formWrapper {
  padding: 1rem;
  background: rgb(29, 29, 29);
  border-radius: 4px;
}
.contactHolder .formWrapper p {
  margin: 2rem 0;
}
.contactHolder .formWrapper .form-control {
  background-color: #242526 !important;
  color: white !important;
}

.projects {
  margin-top: 3rem !important;
}

.howWrapper--title {
  color: white !important;
  text-align: center;
  font-size: 5rem !important;
  margin: 10rem 0;
}
@media all and (max-width: 576px) {
  .howWrapper--title {
    font-size: 1.5rem !important;
    margin: 5rem 0;
  }
}
.howWrapper .workWay {
  text-align: center;
}
.howWrapper .workWay h3 {
  color: white !important;
  text-align: left;
}
.howWrapper .workWay figure {
  margin-top: 3rem;
  background: rgb(44, 44, 44);
  border-radius: 6px;
}
.howWrapper .workWay figure img {
  width: 70%;
}
@media all and (max-width: 768px) {
  .howWrapper .workWay figure img {
    width: 100%;
  }
}
.howWrapper .workWay .workItems h3 {
  margin-top: 3rem;
}
.howWrapper .workWay .workItems figure img {
  height: 35rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  padding: 1rem;
}
@media all and (max-width: 768px) {
  .howWrapper .workWay .workItems figure img {
    height: 25rem;
  }
}
@media all and (max-width: 768px) {
  .howWrapper .workWay .workItems figure img {
    height: 20rem;
  }
}/*# sourceMappingURL=App.css.map */
.homeWrapper {
  margin-top: 1.5rem;
  &-title {
    color: white;
    margin-top: 8rem;
    @include responsive-for(xlg) {
      margin-top: 2rem;
    }
    @include responsive-for(md) {
      margin-top: 1rem;
      font-size: 35px !important;
    }
  }
  &-subtitle {
    color: white;
    font-size: 28px;
    @include responsive-for(xlg) {
      font-size: 24px;
    }
    @include responsive-for(md) {
      font-size: 18px;
    }
  }
  &-desc {
    margin-top: 2rem;
    // width: 60%;
    color: #acacac;
    @include responsive-for(md) {
      font-size: 13px;
    }
  }
  &-buttons {
    margin-top: 3rem;
    .downloadButton {
      color: color(primary, base);
    }
  }
  &-image {
    @include responsive-for(sm) {
      text-align: center;
    }
    img {
      width: 95%;
      border-radius: 4px;
      @include responsive-for(sm) {
        width: 50%;
      }
    }
  }
  &-social {
    color: #acacac;
    // @include responsive-for(md) {
    //   transform: scale(0.75);
    // }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1rem;
      @include responsive-for(sm) {
        flex-direction: row;
        justify-content: space-around;
      }
      li {
        margin-bottom: 1.3rem;
        // @include responsive-for(sm) {
        //   margin-left: 2rem;
        // }

        a {
          height: 34px;
          width: 34px;
          svg {
            border-radius: calc(34px);
            background: #343534;
            color: #ffffff;
            padding: 6px;

            font-size: 28px !important;
          }
        }
      }
      .socialLine {
        height: 5rem;
        width: 0.1rem;
        background: rgb(255, 255, 255);
        @include responsive-for(sm) {
          display: none;
        }
      }
      .scroll {
        writing-mode: vertical-rl;
        letter-spacing: 8px;
        @include responsive-for(md) {
          display: none;
        }
      }
    }
  }
}

//skills section

.skillsHolder {
  margin-top: 5rem;
  padding: 1.5rem;
  // &-title {
  //   text-align: center;
  //   padding: 2rem;
  //   color: white;
  // }

  h2 {
    color: white;
    margin-bottom: 3rem;
  }
  &-item {
    background: #03171b;
    color: #dddddd;
    text-align: center;
    border-radius: 4px;
    padding: 2rem;
    margin-bottom: 1rem;

    svg {
      font-size: 3rem;
    }
    img {
      width: 100%;
      height: 7rem;
      object-fit: contain;
      background: white;
      padding: 0.5rem;
    }
  }
}

//worksWrapper

.worksWrapper {
  color: white;
  margin-top: 8rem;
  &-title {
    text-align: center;
    font-size: 3rem !important;
    margin-bottom: 3rem;
  }
  .mainTitle {
    text-align: center;
    font-size: 9rem !important;
    width: 80%;
    margin: 0 auto;
    @include responsive-for(sm) {
      font-size: 5rem !important;
    }
  }
  .cardsHolder {
    background: #03171b;
    margin-bottom: 5rem;
    border-radius: 4px;
    padding: 2rem;
    &-pills {
      display: flex;
      flex-wrap: wrap;

      span {
        background: #22292b;
        padding: 0.7rem;
        text-transform: uppercase;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        @include responsive-for(xlg) {
          font-size: 13px;
        }
      }
    }

    &-desc {
      margin-top: 3rem;

      &-paragraph {
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: #8d8d8d;
      }
    }
    &-image {
      img {
        width: 100%;
        @include responsive-for(md) {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

footer {
  background: #151616;
  color: rgb(167, 167, 167);
  .footerWrapper {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

//contace

.contactHolder {
  color: white;
  margin-top: 1rem;
  margin-bottom: 5rem;

  .formWrapper {
    padding: 1rem;
    background: rgb(29, 29, 29);
    border-radius: 4px;

    p {
      margin: 2rem 0;
    }

    .form-control {
      background-color: #242526 !important;
      color: white !important;
    }
  }
}

.projects {
  margin-top: 3rem !important;
}

.howWrapper {
  &--title {
    color: white !important;
    text-align: center;
    font-size: 5rem !important;
    margin: 10rem 0;
    @include responsive-for(sm) {
      font-size: 1.5rem !important;
      margin: 5rem 0;
    }
  }

  .workWay {
    h3 {
      color: white !important;
      text-align: left;
    }
    text-align: center;
    figure {
      margin-top: 3rem;
      background: rgb(44, 44, 44);
      border-radius: $radius;
      img {
        width: 70%;
        @include responsive-for(md) {
          width: 100%;
        }
      }
    }

    .workItems {
      h3 {
        margin-top: 3rem;
      }
      figure {
        img {
          height: 35rem;
          width: 100%;
          object-fit: cover;

          padding: 1rem;
          @include responsive-for(md) {
            height: 25rem;
          }
          @include responsive-for(md) {
            height: 20rem;
          }
        }
      }
    }
  }
}
